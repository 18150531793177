import clsx from 'clsx';
import Link from 'next/link';

import { GetTotalDonations } from '@endaoment-frontend/api';
import { useIsMobile, useIsMounted } from '@endaoment-frontend/hooks';
import { routes } from '@endaoment-frontend/routes';
import { LogoTextIcon } from '@endaoment-frontend/ui/icons';
import { convertUsdcToNumber, formatCurrency } from '@endaoment-frontend/utils';

import styles from './SuperHeader.module.scss';

const ActiveLink = ({
  href,
  children,
  target,
  className,
  preciseMatch,
}: {
  href: string;
  target?: string;
  children: React.ReactNode;
  className?: string;
  preciseMatch?: boolean;
}) => {
  const origin = typeof window !== 'undefined' ? window.location.origin : undefined;

  // If `preciseMatch`, check if the href is the same as the current page
  // Otherwise, check if the href starts with the origin
  const isHrefActive: boolean =
    !!origin &&
    (preciseMatch ? href === window.location.href || href === window.location.href + '/' : href.indexOf(origin) === 0);

  const isMounted = useIsMounted();

  return (
    <Link
      href={href}
      target={target}
      data-active={!!isMounted && isHrefActive}
      className={clsx(styles['link'], className)}>
      {children}
    </Link>
  );
};

export const SuperHeader = () => {
  const isMounted = useIsMounted();
  const { isMobile } = useIsMobile();

  const totalDonated = convertUsdcToNumber(GetTotalDonations.useQuery([], { enabled: isMounted }).data);

  return (
    <div className={styles['container']} title='superheader'>
      <div className={styles['super-header']}>
        <div className={styles['logo']}>
          <Link
            // Takes the user to the Marketing Home page always
            // Complaints to be directed to @rheeger
            href={routes.marketing.home()}>
            {isMobile ? 'Home' : <LogoTextIcon color='currentColor' width={106} />}
            {!!isMounted && (
              <span className={clsx(styles['impact-number'], styles['desktop-only'])}>
                Total Impact:
                <b>{formatCurrency(totalDonated, { compact: true })}</b>
              </span>
            )}
          </Link>
        </div>
        <nav className={styles['nav']}>
          {/* About -> Marketing Home 
              This is so on the sister sites the user isn't tempted to click "Home" to go to index page. */}
          <ActiveLink href={routes.marketing.home({ useFullUrl: true })} preciseMatch>
            About
          </ActiveLink>
          <ActiveLink
            href={routes.marketing.donationCalculator({ useFullUrl: true })}
            className={styles['desktop-only']}
            preciseMatch>
            Calculator
          </ActiveLink>
          <ActiveLink href='https://docs.endaoment.org/' target='_blank'>
            Docs
          </ActiveLink>
          <ActiveLink href={routes.developers.home({ useFullUrl: true })}>Developers</ActiveLink>
          <ActiveLink href={routes.app.dashboard({ useFullUrl: true })}>App</ActiveLink>
        </nav>
      </div>
    </div>
  );
};
